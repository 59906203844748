import React, { useState, useEffect, useRef } from "react";
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/List.css";
import "../../styles/Payroll.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const TeamSection = ({
  title,
  type,
  members,
  handleAddMember,
  handleRemoveMember,
  apiUrl,
  token,
  entrepriseId,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [allMembers, setAllMembers] = useState([]); // Liste complète des employés (une seule requête)
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // Récupération initiale des employés (une seule fois)
  useEffect(() => {
    const fetchMembers = async () => {
      setLoadingSearch(true);
      const searchUrl = `${apiUrl}/graphql/`;
      const query = `
        query ($entrepriseId: Int) {
          employes(entrepriseId: $entrepriseId) {
            id
            firstName
            lastName
          }
        }
      `;

      try {
        const response = await fetch(searchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            query,
            variables: { entrepriseId },
          }),
        });

        if (!response.ok) {
          throw new Error("Error while fetching employees.");
        }

        const { data, errors } = await response.json();
        if (errors) {
          throw new Error("GraphQL query failed.");
        }

        setAllMembers(data.employes || []); // Stocke tous les employés
      } catch (error) {
        console.error("Unable to fetch employees.", error);
      } finally {
        setLoadingSearch(false);
      }
    };

    fetchMembers();
  }, [apiUrl, token, entrepriseId]);

  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    if (input.length >= 3) {
      // Filtre uniquement en front-end
      const lowerInput = input.toLowerCase();
      const filtered = allMembers.filter(
        (emp) =>
          emp.firstName.toLowerCase().includes(lowerInput) ||
          emp.lastName.toLowerCase().includes(lowerInput)
      );
      setFilteredMembers(filtered);
      setShowResults(true);
    } else {
      setFilteredMembers([]);
      setShowResults(false);
    }
  };

  return (
    <div className="line-container-10">
      <h5>{title}</h5>
      {members?.length > 0 ? (
        members.map((member) => (
          <p className="display-line-center" key={member.id}>
            {member.firstName} {member.lastName}
            <button
              className="btn-delete"
              onClick={() => handleRemoveMember(member, type)}
            >
              Remove
            </button>
          </p>
        ))
      ) : (
        <p>No {type} assigned.</p>
      )}

      <div ref={searchRef} className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder={`Add a new ${type.slice(0, -1)}`}
          value={searchInput}
          onChange={handleSearchChange}
        />

        {loadingSearch ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          showResults &&
          filteredMembers.length > 0 && (
            <div className="search-results">
              {filteredMembers.map((member) => (
                <div
                  key={member.id}
                  className="search-item"
                  onClick={() => {
                    handleAddMember(member, type);
                    setSearchInput("");
                    setFilteredMembers([]);
                    setShowResults(false);
                  }}
                >
                  {member.firstName} {member.lastName}
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TeamSection;
