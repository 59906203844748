import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter} from '@fortawesome/free-solid-svg-icons';


const SalaryTableau = ({ employees }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({
        name: '',
        position: '',
        department: '',
        startDate: '',
        endDate: '',
        grossSalary: '',
        bonus: '',
        benefits: '',
        employerContributions: '',
        overallCost: ''
    });
    const [showFilters, setShowFilters] = useState({}); // État pour gérer l'affichage des inputs

    const itemsPerPage = 15;

    // Fonction pour filtrer les employés
    const filteredEmployees = employees.filter((employee) =>
        Object.keys(filters).every((key) => {
            const filterValue = filters[key].toLowerCase();
            const employeeValue = employee[key] ? employee[key].toString().toLowerCase() : '';
            return filterValue === '' || employeeValue.includes(filterValue);
        })
    );

    // Pagination des employés filtrés
    const paginatedEmployees = filteredEmployees.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);

    const goToPreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));

    // Fonction pour mettre à jour les filtres
    const handleFilterChange = (field, value) => {
        setFilters({ ...filters, [field]: value });
        setCurrentPage(1); // Revenir à la première page lors du filtrage
    };

    // Afficher ou masquer un champ de filtre
    const toggleFilterInput = (field) => {
        setShowFilters({ ...showFilters, [field]: !showFilters[field] });
    };

    return (
        <div className="table-salary-container">


            <table className="positions-table">
                <thead>
                    <tr>
                        {/* Titres des colonnes avec icône filtre */}
                        {Object.keys(filters).map((key) => (
                            <th key={key} style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                                 

                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {paginatedEmployees.map((employee) => (
                        <tr key={employee.id}>
                            <td>{employee.name}</td>
                            <td>{employee.position}</td>
                            <td>{employee.department}</td>
                            <td>
                                {employee.startDate
                                    ? new Date(employee.startDate).toLocaleDateString('fr-FR')
                                    : 'N/A'}
                            </td>
                            <td>
                                {employee.endDate
                                    ? new Date(employee.endDate).toLocaleDateString('fr-FR')
                                    : 'N/A'}
                            </td>
                            <td>
                                {employee.grossSalary.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}{' '}€
                            </td>
                            <td>
                                {employee.bonus.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}{' '}€
                            </td>
                            <td>
                                {employee.benefits.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}{' '}€
                            </td>
                            <td>
                                {employee.employerContributions.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}{' '}€
                            </td>
                            <td>
                                {employee.overallCost.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}{' '}€
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="hc-pagination-control">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default SalaryTableau;
