import React, { useState, useEffect } from "react";
import logo_lucca from "../../assets/logo-lucca.png";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const ChatSyncDemo = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = () => {
    if (!input.trim()) return;

    const now = new Date().toLocaleTimeString([], { timeStyle: "short" });
    setMessages((prev) => [
      ...prev,
      { sender: "user", text: input.trim(), time: now },
    ]);
    setInput("");

    if (!syncComplete) {
      setIsLoading(true);
      const botMessages = [
        { sender: "bot", text: "Syncro Lucca faite" },
        { sender: "bot", text: "Les données du SIRH sont complètes." },
        {
          sender: "bot",
          text: "Veuillez vérifier les personnes avec l'id 8248 et 9294.",
        },
        {
          sender: "bot",
          text: "Les données d'équipe de ces deux personnes ne paraissent pas cohérentes.",
          hasButton: true,
        },
      ];

      botMessages.forEach((msg, index) => {
        setTimeout(() => {
          const time = new Date().toLocaleTimeString([], { timeStyle: "short" });
          setMessages((prev) => [...prev, { ...msg, time }]);

          if (index === botMessages.length - 1) {
            setSyncComplete(true);
            setIsLoading(false);
          }
        }, 4500+ index*400);
      });
    }

    // Simule un délai de réponse côté bot (facultatif)
    setIsBotTyping(true);
    setTimeout(() => {
      setIsBotTyping(false);
    }, 4500);
  };

  return (
    <>
      {/* Définition de l’animation spinner dans un style global intégré */}
      <style>{`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .spinner {
          width: 15px;
          height: 15px;
          border: 2px solid #007bff;
          border-radius: 50%;
          border-top-color: transparent;
          animation: spin 1s linear infinite;
          /* Effet de lueur autour */
          box-shadow: 0 0 8px 2px rgba(0, 123, 255, 0.6);
        }
      `}</style>

      <div style={styles.container}>
        <div style={styles.mainContent}>
          <div style={styles.chatContainer}>
            <h5 style={styles.chatTitle}>Agent Polare</h5>
            <div style={styles.messageList}>
              {messages.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.messageWrapper,
                    justifyContent:
                      msg.sender === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  <div
                    style={{
                      ...styles.messageBubble,
                      backgroundColor:
                        msg.sender === "user" ? "#b2f5ea" : "#e2e8f0",
                    }}
                  >
                    <div style={styles.messageText}>
                      {msg.text}
                      {msg.isSyncing && (
                        <img
                          src={logo_lucca}
                          className="logo"
                          alt="Logo"
                          style={styles.loader}
                        />
                      )}
                    </div>
                    {msg.hasButton && (
                      <button
                        style={styles.luccaButton}
                        onClick={() =>
                          window.open("https://lucca.example.com", "_blank")
                        }
                      >
                        Vérifier sur Lucca
                      </button>
                    )}
                    <div style={styles.messageTime}>{msg.time}</div>
                  </div>
                </div>
              ))}
            </div>

            <div style={styles.inputContainer}>
              {isBotTyping && (
                <div style={styles.typingIndicator}>
                  {isLoading && (
                    <div style={styles.loaderContainer}>
                      <img src={logo_lucca} alt="Loader" style={styles.loader} />
                      {/* Appliquez la classe "spinner" pour la rotation + lueur */}
                      <div className="spinner"></div>
                    </div>
                  )}
                </div>
              )}
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                placeholder="Tapez votre message..."
                style={styles.inputField}
              />
              <button onClick={handleSendMessage} style={styles.sendButton}>
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSyncDemo;

// -- STYLES --
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "600px", // Taille fixe pour le chat
    margin: 0,
    width: "500px",
    fontFamily: "Arial, sans-serif",
  },
  chatTitle: {
    backgroundColor: "#00667A",
    color: "#fff",
    padding: "15px",
    borderRadius: "10px",
    textAlign: "center",
  },
  header: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "15px",
    textAlign: "center",
  },
  mainContent: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    padding: "20px",
    gap: "20px",
    overflow: "hidden",
  },
  chatContainer: {
    flex: 1,
    border: "1px solid #ccc",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: "#fff",
  },
  messageList: {
    flex: 1,
    overflowY: "auto",
    paddingRight: "5px",
    marginBottom: "10px",
  },
  messageWrapper: {
    display: "flex",
    marginBottom: "10px",
  },
  messageBubble: {
    maxWidth: "60%",
    padding: "8px 12px",
    borderRadius: "10px",
    position: "relative",
  },
  messageText: {
    marginBottom: "4px",
    width: "100%",
  },
  messageTime: {
    fontSize: "0.8em",
    color: "#555",
    textAlign: "right",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  typingIndicator: {
    position: "absolute",
    top: -40,
    left: 0,
    fontStyle: "italic",
    color: "#666",
    fontSize: "0.9em",
  },
  inputField: {
    flex: 1,
    padding: "8px",
    marginRight: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  sendButton: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#00667A",
    color: "white",
    cursor: "pointer",
  },
  luccaButton: {
    marginTop: "5px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#00667A",
    color: "white",
    cursor: "pointer",
  },
  loader: {
    width: "100px",
    height: "37px",
    marginLeft: "8px",
    
    verticalAlign: "middle",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width: "150px",
    marginBottom: "20px",
  },
};
