import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import "../../../styles/Chart.css";
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphSignedNotSignedbyTA = () => {
    const currentYear = new Date().getFullYear();
    const endOfYear = new Date(`${currentYear}-12-31`);
    const lastYear = new Date(`${currentYear}-01-01`);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(endOfYear));
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [kpiData, setKpiData] = useState({ currentActual: 0, currentForecast: 0, actualChange: 0, forecastChange: 0, lastPeriod: '' });
    const [tableData, setTableData] = useState([]);
    const [openPositions, setOpenPositions] = useState([]);

    const processChartData = (data) => {
        const groupedData = data.reduce((acc, position) => {
            const recruiterName = position.recruiter ? position.recruiter.firstName : 'Unknown';
            if (!acc[recruiterName]) {
                acc[recruiterName] = { status_6_7: 0, others: 0 };
            }
            if (position.status.includes('6') || position.status.includes('7')) {
                acc[recruiterName].status_6_7++;
            } else {
                acc[recruiterName].others++;
            }
            return acc;
        }, {});

        const labels = Object.keys(groupedData);
        const status_6_7_data = labels.map(label => groupedData[label].status_6_7);
        const others_data = labels.map(label => groupedData[label].others);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Signed',
                    data: status_6_7_data,
                    borderColor: '#A54C53',
                    backgroundColor: '#A54C53',
                    fill: true,
                    tension: 0.1
                },
                {
                    label: 'To Sign',
                    data: others_data,
                    borderColor: '#A54C5360',
                    backgroundColor: '#A54C5360',
                    fill: true,
                    tension: 0.1
                },
            ],
        });

        const tableRows = labels.map(label => ({
            recruiter: label,
            status_6_7: groupedData[label].status_6_7,
            others: groupedData[label].others,
        }));

        setTableData(tableRows);

        const totalSigned = status_6_7_data.reduce((sum, value) => sum + value, 0);
        const totalToSign = others_data.reduce((sum, value) => sum + value, 0);

        setKpiData(prevKpiData => ({
            ...prevKpiData,
            currentActual: totalSigned,
            currentForecast: totalToSign
        }));
    };

    useEffect(() => {
        const fetchEmploymentData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const whatsNewUrl = `${apiUrl}/graphql/`;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                console.error('Entreprise ID not found in userData');
                return;
            }

            const appliedFilters = filters.reduce((acc, filter) => {
                if (filter.column === 'location') {
                    acc.location = filter.value; // Filtrage par location unique
                } else if (filter.column === 'team') {
                    acc.teamId = parseInt(filter.value, 10); // Filtrage par ID d'équipe
                }
                return acc;
            }, {});

            const query = `
                query ($entrepriseId: Int!, $startDateOpening: Date!, $endDateOpening: Date!, $location: String, $teamId: Int) {
                    openPositions(
                        entrepriseId: $entrepriseId,
                        startDateOpening: $startDateOpening,
                        endDateOpening: $endDateOpening,
                        location: $location,
                        teamId: $teamId
                    ) {
                        id
                        status
                        arrivalMonth
                        jobOpeningDate
                        recruiter {
                            id
                            firstName
                        }
                    }
                }
            `;

            const variables = {
                entrepriseId,
                startDateOpening: startDate, // Utilisation de la bonne variable
                endDateOpening: endDate,     // Utilisation de la bonne variable
                ...appliedFilters,
            };

            try {
                const response = await fetch(whatsNewUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();

                if (responseData.errors) {
                    console.error('GraphQL errors:', responseData.errors);
                    return;
                }
                setOpenPositions(responseData.data.openPositions);
                processChartData(responseData.data.openPositions);

            } catch (error) {
                console.error('Error fetching employment data:', error);
            }
        };

        fetchEmploymentData();
    }, [startDate, endDate, filters]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;

        setCurrentFilter((prev) => ({
            ...prev,
            [name]: value || '',
        }));
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            let displayValue = '';

            if (currentFilter.column === 'team') {
                const selectedTeam = filterOptions.teams.find(team => team.id === parseInt(currentFilter.value, 10));
                displayValue = selectedTeam ? selectedTeam.nom_equipe : '';
            } else if (currentFilter.column === 'location') {
                displayValue = currentFilter.value;
            }

            setFilters((prev) => [
                ...prev,
                { ...currentFilter, displayValue },
            ]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        } else {
            console.error('Filter column or value is missing.');
        }
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const fetchFilterOptions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/open_positions_signed/`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching filter options');
            }

            const data = await response.json();
            setFilterOptions(data.filters);

        } catch (error) {
            console.error('Error fetching filter options', error);
        }
    };

    useEffect(() => {
        fetchFilterOptions();
    }, []);

    const chartOptions = {
        responsive: true,
        scales: {
            x: { title: { display: true, text: 'Recruiter' }, stacked: true },
            y: { title: { display: true, text: 'Number of Open Positions' }, stacked: true },
        },
    };

    const tableColumns = [
        { label: 'Recruiter', key: 'recruiter' },
        { label: 'Signed', key: 'status_6_7' },
        { label: 'To Sign', key: 'others' },
    ];

    const exportToCSV = () => {
        if (!tableData || tableData.length === 0) {
            console.error('No data to export');
            return;
        }

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += tableColumns.map((col) => col.label).join(",") + "\n";

        tableData.forEach((item) => {
            const row = tableColumns.map((col) => item[col.key]).join(",");
            csvContent += row + "\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "open_positions_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar isCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">
                    <FiltersComponent
                        showDateRange={true}
                        showGroupOption={true}
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={(e) => setStartDate(e.target.value)}
                        handleEndDateChange={(e) => setEndDate(e.target.value)}
                        groupOption={groupOption}
                        handleGroupChange={(e) => setGroupOption(e.target.value)}
                        filterOptions={filterOptions}
                        currentFilter={currentFilter}
                        handleFilterChange={handleFilterChange}
                        handleAddFilter={handleAddFilter}
                        filters={filters}
                        handleRemoveFilter={handleRemoveFilter}
                    />
                    <div className='container-data'>
                        <ChartWithKPIsComponent
                            title="Recruitment progress by TA"
                            kpiDataArray={[
                                { title: "Signed", value: kpiData.currentActual },
                                { title: "To Sign", value: kpiData.currentForecast }
                            ]}
                            chartType="Line"
                            chartData={chartData}
                            chartOptions={chartOptions}
                        />
                        <div className='card-full'>
                            <TableComponent
                                title="Open Positions Data"
                                columns={tableColumns}
                                data={tableData || []}
                                exportToCSV={exportToCSV}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphSignedNotSignedbyTA;
