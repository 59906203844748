import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import logo_txt from "../assets/logo_txt.jpg";
import logo_img from "../assets/logo.png";
import colors from '../assets/colors.js';
import ButtonSidebar from './buttons/ButtonSidebar';
import { faHouse, faHeart, faElevator, faPeopleGroup, faChartArea, faClipboardList, faPeopleArrows, faMoneyBill, faBrain } from '@fortawesome/free-solid-svg-icons';
import ButtonLogout from './buttons/ButtonLogout';
import ButtonReset from './buttons/ButtonReset';

const Sidebar = ({ initiallyCollapsed = false, isCollapsed: parentCollapsed }) => {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

  // Synchronisation avec la prop parentCollapsed
  useEffect(() => {
    setIsCollapsed(parentCollapsed);
  }, [parentCollapsed]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  const { groupes, autorisation_features } = userData;
  const features = Array.isArray(autorisation_features) ? autorisation_features : [];

  return (
    <div style={{ backgroundColor: colors.background, width: isCollapsed ? '80px' : '220px' }} className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className='sidebar-top'>
        <img src={logo_txt} className='logo' alt="Logo" style={{ display: isCollapsed ? 'none' : 'block' }} />
        <img src={logo_img} className='logo_img' alt="Logo" style={{ display: !isCollapsed ? 'none' : 'block' }} />

        <ButtonSidebar text="Home" icon={faHouse} redirectTo="/" isCollapsed={isCollapsed} />

        {features.includes('HR Planification') && (
          <ButtonSidebar text="HR Planification" icon={faPeopleArrows} redirectTo="/headcount" isCollapsed={isCollapsed} />
        )}

        {features.includes('Effectif') && (
          <ButtonSidebar text="Payroll Tracking" icon={faMoneyBill} redirectTo="/payroll" isCollapsed={isCollapsed} />
        )}

        {features.includes('Staffing Plan') && (
          <ButtonSidebar text="Staffing Plan" icon={faClipboardList} redirectTo="/staffingplan" isCollapsed={isCollapsed} />
        )}
        {features.includes('Performance') && (
          <ButtonSidebar text="Performance" icon={faChartArea} redirectTo="/performance" isCollapsed={isCollapsed} />
        )}
                {features.includes('Agents') && (
          <ButtonSidebar text="AI Agents" icon={faBrain} redirectTo="/agents/" isCollapsed={isCollapsed} />
        )}
      </div>

      <div className='sidebar-bottom'>
        <ButtonLogout isCollapsed={isCollapsed} />
        <ButtonReset isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default Sidebar;
