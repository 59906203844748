import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "../../styles/ScenariHR.css"; // Reuse existing styles

const CreateTeamForm = ({ token, onTeamCreated, onClose }) => {
    const modalRef = useRef(null);
    const [teamName, setTeamName] = useState("");
    const [description, setDescription] = useState("");
    const [mainManager, setMainManager] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Retrieve company ID from local storage
    const userData = JSON.parse(localStorage.getItem("userData"));
    const companyId = userData ? userData.entreprise_id : null;

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!teamName) {
            setErrorMessage("Team name is required.");
            return;
        }

        if (!companyId) {
            setErrorMessage("Unable to retrieve company ID.");
            return;
        }

        const mutation = `
            mutation CreateTeam($teamName: String!, $description: String, $mainManager: Int, $companyId: Int!) {
                createEquipe(nomEquipe: $teamName, description: $description, mainManager: $mainManager, entrepriseId: $companyId) {
                    equipe {
                        id
                        nomEquipe
                        description
                        mainManager {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        `;

        const variables = {
            teamName,
            description,
            mainManager: mainManager ? parseInt(mainManager, 10) : null,
            companyId,
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            const { data, errors } = await response.json();

            if (!response.ok || errors) {
                throw new Error("Error creating the team.");
            }

            setIsSubmitted(true);
            setTimeout(() => {
                setIsSubmitted(false);
                onTeamCreated(); // Refresh the list
                onClose(); // Close the modal
            }, 1500);
        } catch (error) {
            setErrorMessage("Unable to create the team.");
        }
    };

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-container" ref={modalRef}>
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <h5>Create a New Team</h5>

                <form className="hc-form" onSubmit={handleSubmit}>
                    <div className="hc-form-line">
                        <label>Team Name:</label>
                        <input
                            type="text"
                            name="teamName"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            required
                        />
                    </div>
             
                    <button type="submit">Create Team</button>
                </form>

                {/* Success animation */}
                {isSubmitted && (
                    <div className="validation-feedback">
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                        <p>Team created successfully!</p>
                    </div>
                )}

                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default CreateTeamForm;
