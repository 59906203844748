import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import colors from '../../../assets/colors';
import Header from '../../../components/Header';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const GraphPayroll = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [filters, setFilters] = useState({
    dateRange: {
      start: `${currentYear}-01-01`,
      end: `${currentYear}-12-31`,
    },
    groupBy: urlParams.groupBy || 'all',
    team: urlParams.team || 'all',
    costType: urlParams.costType || 'all',
  });
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const entrepriseId = userData?.entreprise_id || null;

  useEffect(() => {
    if (entrepriseId) {
      fetchTeams();
      fetchPayrollData();
    }
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query GetTeams($entrepriseId: Int!) {
              equipes(entrepriseId: $entrepriseId) {
                id
                nomEquipe
              }
            }
          `,
          variables: { entrepriseId },
        }),
      });
      const data = await response.json();
      if (data.errors) throw new Error(data.errors[0].message);

      setTeams(data.data.equipes || []);
      
    } catch (err) {
      console.error('Error fetching teams:', err);
      setError('Une erreur est survenue lors de la récupération des équipes.');
    }
  };
  const fetchPayrollData = async () => {
    setLoading(true);
    setError(null);
    try {
      const variables = {
        entrepriseId,
        startDate: filters.dateRange.start,
        endDate: filters.dateRange.end,
        groupBy: filters.groupBy !== 'all' ? filters.groupBy : undefined,
        teamId: filters.team !== 'all' ? parseInt(filters.team, 10) : undefined, 
      };
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query GetKpiSalaryByMonth(
              $entrepriseId: Int!, 
              $startDate: String!, 
              $endDate: String!, 
              $groupBy: String,
              $teamId: Int
            ) {
              kpiSalaryFromHistoriqueMonth(
                entrepriseId: $entrepriseId,
                startDate: $startDate,
                endDate: $endDate,
                groupBy: $groupBy,
                teamId: $teamId
              ) {
                group
                data {
                  month
                  variableCost
                  fixedCost
                  totalCost
                }
              }
            }
          `,
          variables,
        }),
      });
  
      const responseData = await response.json();
      if (responseData.errors) {
        throw new Error(responseData.errors[0].message);
      }
  
      processChartData(responseData.data.kpiSalaryFromHistoriqueMonth || []);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Une erreur est survenue lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };

  const processChartData = (data) => {
    if (!data || data.length === 0) {
      setChartData({ labels: [], datasets: [] });
      return;
    }
  
    const labels = data[0]?.data.map((item) => item.month) || [];
    let datasets = [];
  
    if (filters.groupBy === "all") {
      // Pas de groupBy, affiche une seule série de données
      let costData;
      let label;
  
      if (filters.costType === "fixed") {
        costData = data[0]?.data.map((item) => item.fixedCost) || [];
        label = "Fixed Cost (€)";
      } else if (filters.costType === "variable") {
        costData = data[0]?.data.map((item) => item.variableCost) || [];
        label = "Variable Cost (€)";
      } else {
        // costType === "all"
        costData = data[0]?.data.map((item) => item.totalCost) || [];
        label = "Total Cost (€)";
      }
  
      const activeTeam = filters.team !== "all"
        ? teams.find((team) => team.id === filters.team)?.nomEquipe
        : null;
        console.log("teams", teams);
      console.log("activeTeam", activeTeam);

  
      datasets = [
        {
          label: activeTeam || label, // Affiche le nom de l'équipe si un filtre est actif
          data: costData,
          borderColor: getColorForGroup(activeTeam || label, 1),
          backgroundColor: getColorForGroup(activeTeam || label, 0.8),
        },
      ];
    } else if (filters.groupBy === "costType") {
      // Regroupement par type de coût
      const firstGroup = data[0];
      if (!firstGroup) {
        setChartData({ labels: [], datasets: [] });
        return;
      }
  
      const variableCosts = firstGroup.data.map((item) => item.variableCost);
      const fixedCosts = firstGroup.data.map((item) => item.fixedCost);
  
      if (filters.costType === "all" || filters.costType === "variable") {
        datasets.push({
          label: "Variable Cost (€)",
          data: variableCosts,
          borderColor: getColorForGroup("Variable Cost (€)", 1),
          backgroundColor: getColorForGroup("Variable Cost (€)", 0.8),
        });
      }
  
      if (filters.costType === "all" || filters.costType === "fixed") {
        datasets.push({
          label: "Fixed Cost (€)",
          data: fixedCosts,
          borderColor: getColorForGroup("Fixed Cost (€)", 1),
          backgroundColor: getColorForGroup("Fixed Cost (€)", 0.8),
        });
      }
    } else if (filters.groupBy === "team") {
      // Regroupement par équipe
      datasets = data.map((groupItem) => {
        let costData;
  
        if (filters.costType === "fixed") {
          costData = groupItem.data.map((item) => item.fixedCost);
        } else if (filters.costType === "variable") {
          costData = groupItem.data.map((item) => item.variableCost);
        } else {
          // costType === "all"
          costData = groupItem.data.map((item) => item.totalCost);
        }
  
        return {
          label: groupItem.group || "Unknown Group",
          data: costData,
          borderColor: getColorForGroup(groupItem.group, 1),
          backgroundColor: getColorForGroup(groupItem.group, 0.8),
        };
      });
    }
  
    setChartData({
      labels,
      datasets,
    });
  };
  

  const colorMapRef = React.useRef({});


  const getColorForGroup = (group, alpha = 1) => {
    // If we already have a color for this group, return it
    if (colorMapRef.current[group]) {
      return colorMapRef.current[group].replace(/, [^,]+\)$/, `, ${alpha})`);
    }
  
    // Otherwise, generate one and store it
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
  
    const color = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    // Store color at full opacity, so we can reuse it with different alpha
    colorMapRef.current[group] = `rgba(${r}, ${g}, ${b}, 1)`;
    console.log("colorMapRef", colorMapRef);
    return color;
  };




  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    fetchPayrollData(filters);
    console.log("filters", filters);
  }, [filters]); //


  const removeFilter = (filter) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: filter === 'dateRange' ? { start: `${currentYear}-01-01`, end: `${currentYear}-12-31` } : 'all',
    }));
  };


  const renderActiveFilters = () => {
    const activeFilters = Object.entries(filters)
      .filter(([key, value]) => {
        // For dateRange, consider it an active filter if it differs from the current year's default
        if (key === 'dateRange') {
          return (
            value.start !== `${currentYear}-01-01` || value.end !== `${currentYear}-12-31`
          );
        }
        // For any other filter, consider it active if it's not "all"
        return value !== 'all';
      })
      .map(([key, value]) => {
        if (key === 'dateRange') {
          return {
            key,
            label: `Dates: ${value.start} - ${value.end}`,
          };
        }
  
        // Special handling for the "team" key
        if (key === 'team') {
          // Find the matching team in the `teams` array
          const teamObj = teams.find((t) => t.id === value, 10);
          const teamName = teamObj ? teamObj.nomEquipe : value; // fallback to the raw value if no match
          return {
            key,
            label: `Équipe: ${teamName}`,
          };
        }
  
        // Default: "key: value"
        return {
          key,
          label: `${key}: ${value}`,
        };
      });
  
    return (
      <div className="line-container-10">
        {activeFilters.map((filter) => (
          <span key={filter.key} className="filter-tag">
            {filter.label}
            <button onClick={() => removeFilter(filter.key)} className="remove-filter-button">
              ✕
            </button>
          </span>
        ))}
      </div>
    );
  };
  

  return (
    <div style={{ backgroundColor: colors.background }} className="container-home">
      <Sidebar isCollapsed={true} />
      <div className="container-right">
        <Header />

        <div className="content-container">
          <div className="filters-container">
            <div className="card-full">
              <h5>Dates</h5>
              <label>
                Date de début :
                <input
                  type="date"
                  value={filters.dateRange.start}
                  className="chart-dates"
                  onChange={(e) => handleFilterChange('dateRange', {
                    ...filters.dateRange,
                    start: e.target.value,
                  })}
                />
              </label>
              <label>
                Date de fin :
                <input
                  type="date"
                  value={filters.dateRange.end}
                  className="chart-dates"
                  onChange={(e) => handleFilterChange('dateRange', {
                    ...filters.dateRange,
                    end: e.target.value,
                  })}
                />
              </label>

              <h5>Group By</h5>
              <select
                value={filters.groupBy}
                className="chart-dates"
                onChange={(e) => handleFilterChange('groupBy', e.target.value)}
              >
                <option value="all">Tous</option>
                <option value="costType">Type de Coût</option>
                <option value="team">Équipe</option>
              </select>

              <h5>Équipe</h5>
              <select
                value={filters.team}
                className="chart-dates"
                onChange={(e) => handleFilterChange('team', e.target.value)}
              >
                <option value="all">Toutes les équipes</option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.nomEquipe}
                  </option>
                ))}
              </select>

              <h5>Type de Coût</h5>
              <select
                value={filters.costType}
                className="chart-dates"
                onChange={(e) => handleFilterChange('costType', e.target.value)}
              >
                <option value="all">Tous les coûts</option>
                <option value="fixed">Coûts Fixes</option>
                <option value="variable">Coûts Variables</option>
              </select>
              {renderActiveFilters()}
            </div>
           
          </div>

          <div className="card-full">
            {loading ? (
              <div className="loader">Chargement...</div>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { position: 'top' },
                    title: { display: true, text: 'Évolution des coûts salariaux (mois par mois)' },
                  },
                  scales: {
                    x: { stacked: true, title: { display: true, text: 'Mois' } },
                    y: { stacked: true, title: { display: true, text: 'Coût (€)' } },
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphPayroll;
