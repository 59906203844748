import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

const InsightPayrollTeam = () => {
    const [chartData, setChartData] = useState(null);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const [forecast12months, setForecast12months] = useState('Chargement...');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const startDate = '2025-01-01';
    const endDate = '2025-12-31';

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            graph: 'Insight Payroll by Team',
            description: 'Staff costs grouped by team',
        });

        navigate('/payroll/salary?groupBy=team');
    };

    const fetchSalaryData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData ? userData.entreprise_id : null;

        const query = `
            query GetKpiSalaryByMonth($entrepriseId: Int!, $startDate: String!, $endDate: String!) {
                kpiSalaryFromHistoriqueMonth(
                    entrepriseId: $entrepriseId,
                    startDate: $startDate,
                    endDate: $endDate,
                    groupBy: "team"
                ) {
                    group
                    data {
                        month
                        variableCost
                        fixedCost
                        totalCost
                    }
                }
            }
        `;

        const variables = { entrepriseId, startDate, endDate };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            const responseData = await response.json();

            if (responseData.errors) {
                setError(`Erreur: ${responseData.errors[0].message}`);
                return;
            }

            const kpiData = responseData.data.kpiSalaryFromHistoriqueMonth || [];
            processChartData(kpiData);
        } catch (err) {
            setError(`Erreur lors de la récupération des données : ${err.message}`);
        }
    };
    const processChartData = (data) => {
        // Vérifier et traiter les données pour chaque équipe
        const groupedData = data.map((item) => {
            const lastMonthData = item.data && item.data.length > 0 ? item.data[item.data.length - 1] : null;
            return {
                team: item.group,
                variableCost: lastMonthData?.variableCost || 0,
                fixedCost: lastMonthData?.fixedCost || 0,
                totalCost: lastMonthData?.totalCost || 0,
            };
        });
    
        // Trier les équipes par coût total décroissant
        const sortedData = groupedData.sort((a, b) => b.totalCost - a.totalCost);
        const top3Teams = sortedData.slice(0, 3);
    
        const labels = top3Teams.map((item) => item.team || 'Unknown Team');
        const variableCosts = top3Teams.map((item) => item.variableCost);
        const fixedCosts = top3Teams.map((item) => item.fixedCost);
    
        // Calculer le coût total pour le KPI global
        const totalCost = groupedData.reduce((sum, item) => sum + item.totalCost, 0);
        setForecast12months(`${totalCost.toLocaleString()} €`);
    
        // Identifier l'équipe la plus chère
        const mostExpensiveTeam = sortedData[0];
        const mostExpensiveTeamText = mostExpensiveTeam
            ? `${mostExpensiveTeam.team || 'Unknown Team'}: ${mostExpensiveTeam.totalCost.toLocaleString()} €`
            : 'No data available';
    
        // Mettre à jour le sous-titre
        setLastRefreshDate(mostExpensiveTeamText);
    
        // Construire les données du graphique
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Variable Cost',
                    data: variableCosts,
                    backgroundColor: 'gba(87, 117, 144, 0.8)', // Bleu
                    borderColor: 'rgba(87, 117, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Fixed Cost',
                    data: fixedCosts,
                    backgroundColor: 'rgba(243, 114, 44, 0.8)', // Rouge
                    borderColor: 'rgba(243, 114, 44, 1)',
                    borderWidth: 1,
                },


            ],
        });
    };
    

    useEffect(() => {
        const storedData = localStorage.getItem('InsightPayrollTeam');
        if (storedData) {
            const { data } = JSON.parse(storedData);
            processChartData(data);
        } else {
            fetchSalaryData();
        }
    }, []);

    const chartOptions = {
        indexAxis: 'y',
        scales: {
            x: { stacked: true, display: false, grid: { display: false } },
            y: { stacked: true, beginAtZero: true, grid: { display: false } },
        },
        responsive: true,
        plugins: {
            legend: { display: false },
        },
    };

    if (error) return <div>{error}</div>;

    return (
        <InsightCard
            text="Staff cost grouped by team"
            subtext={lastRefreshDate}
            kpi={forecast12months}
            data={
                chartData && (
                    <Bar data={chartData} options={chartOptions} height={110} width={300} />
                )
            }
            onClick={handleOnClick}
        />
    );
};

export default InsightPayrollTeam;
