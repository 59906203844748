import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "../../styles/Home.css";
import "../../styles/Card.css";
import { useParams } from 'react-router-dom';

const ImportStaff = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const { reviewId } = useParams();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setIsSuccessful(false); // Reset success state on file change
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            return;
        }

        setIsLoading(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData')); // Récupérer userData
            const entrepriseId = userData?.entreprise_id; // Extraire entreprise_id

            const formData = new FormData();
            formData.append('file', file);
            if (entrepriseId) {
                formData.append('entreprise_id', entrepriseId); // Ajouter entreprise_id
            }

            const response = await fetch(`${apiUrl}/api/staffing_plan/upload/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setIsSuccessful(true);
        } catch (error) {
            console.error('There was an error uploading the file:', error);
            setIsSuccessful(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData')); // Récupérer userData
            const entrepriseId = userData?.entreprise_id; // Extraire entreprise_id
    
            if (!entrepriseId) {
                console.error('entreprise_id is missing');
                return;
            }
    
            const formData = new FormData();
            formData.append('entreprise_id', entrepriseId); // Ajouter entreprise_id
    
            const response = await fetch(`${apiUrl}/api/staffing_plan/download/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                },
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Générer un nom de fichier basé sur la date actuelle
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0]; // Format YYYY-MM-DD
            const defaultFileName = `open_positions_${formattedDate}.csv`;
    
            const contentDisposition = response.headers.get('Content-Disposition');
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const fileName = fileNameMatch ? fileNameMatch[1] : defaultFileName;
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // Utiliser le nom de fichier généré ou reçu
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        }
    };
    
    

    return (
        <div className='line-container-10'>
            <div className='card-half-full'>
                <div className='line-container-10'>
                    <h5>Add your data:</h5>
                    <form className="form-column" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            {!isLoading && !isSuccessful && (
                                <input
                                    id="fileInput"
                                    type='file'
                                    accept='.csv'
                                    onChange={handleFileChange}
                                    disabled={isLoading}
                                />
                            )}
                            {isLoading ? (
                                <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
                            ) : isSuccessful ? (
                                <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
                            ) : null}
                        </div>
                        <div className="display-line">
                            {!isLoading && !isSuccessful && (
                                <button type='submit' disabled={isLoading}>Upload</button>
                            )}
                            <button className='btn-secondary' onClick={handleDownload} disabled={isLoading}>
                                Download
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-half-full-info-box">
                <h5>How it works:</h5>
                <p>
                    - <b>Download:</b> Clicking the download button will export all existing data to a CSV file.
                </p>
                <p>
                    - <b>Upload:</b> You can use the downloaded file as a base to re-upload data. If you intend to update existing records, leave the "ID" column intact. To create new records, make sure to remove or leave the "ID" column empty.
                </p>
            </div>
        </div>
    );
};

export default ImportStaff;
