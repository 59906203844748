import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import KPISection from '../../../components/hc/KPISection';
import FuturePositionForm from '../../../components/hc/FuturePositionForm';
import FuturePositionsTable from '../../../components/hc/FuturePositionsTable';
import GraphMonthlyView from '../../../components/hc/GraphMonthlyView';
import ChatBotComponent from '../../../components/cards/ChatBotComponent';

const HCScenarioFin = () => {
    const { scenarioId } = useParams();
    const [futurePositions, setFuturePositions] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [masseSalarialeChargee, setMasseSalarialeChargee] = useState(0);
    const [nombrePositions, setNombrePositions] = useState(0);
    const [budget, setBudget] = useState(0);
    const [name, setName] = useState(0);
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [filteredPositions, setFilteredPositions] = useState([]);

    const [formData, setFormData] = useState({
        id: '',
        job: '',
        justification: '',
        salaryCharged: '',
        arrivalMonth: '',
        teamId: '',
        status: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const entrepriseId = userData?.entreprise_id;
    const userId = userData?.id;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Efface les messages d'erreur ou de succès après 3 secondes
    useEffect(() => {
        if (error || successMessage) {
            const timer = setTimeout(() => {
                setError('');
                setSuccessMessage('');
            }, 3000);
            return () => clearTimeout(timer); // Nettoyage si le composant est démonté
        }
    }, [error, successMessage]);

    // Fetch future positions
    const fetchFuturePositions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const query = `
            query ($scenarioId: Int!) {
                futureOpenPosition(scenarioId: $scenarioId) {
                    id
                    job
                    salaryCharged
                    arrivalMonth
                    status
                    
                    requestedBy{
                        id
                        firstName
                        lastName
                    }
                    justification
                    team {
                        id
                        nomEquipe
                    }
                    entreprise {
                        id
                        nomEntreprise
                    }
                }
            }
        `;
        const variables = { scenarioId: parseInt(scenarioId, 10) };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Error: ${responseData.errors[0].message}`);
                return;
            }
            setFuturePositions(responseData.data.futureOpenPosition || []);
        } catch (error) {
            setError(`Error fetching future open positions: ${error.message}`);
        }
    };

    useEffect(() => {

        fetchFuturePositions();
    }, [scenarioId]);

    const updateFuturePositions = async () => {
        await fetchFuturePositions();
        fetchMasseSalarialeChargee(); // Mettez aussi à jour les KPI
    };
    
    // Fetch teams
    useEffect(() => {
        const fetchEquipes = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query equipes($entrepriseId: Int!) {
                    equipes(entrepriseId: $entrepriseId) {
                        id
                        nomEquipe
                    }
                }
            `;
            const variables = { entrepriseId: parseInt(entrepriseId, 10) };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }
                setEquipes(responseData.data.equipes || []);
            } catch (error) {
                setError(`Error fetching teams: ${error.message}`);
            }
        };

        fetchEquipes();
    }, [entrepriseId]);

    const fetchMasseSalarialeChargee = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const query = `
             query masseSalarialeChargee($scenarioId: Int!, $entrepriseId: Int!, $startDate : String!, $endDate : String!) {
                masseSalarialeChargee(scenarioId: $scenarioId, entrepriseId: $entrepriseId, startDate:$startDate, endDate: $endDate) {
                    masseSalarialeChargee
                    nombrePositions
                }
            }
        `;
        const variables = {
            scenarioId: parseInt(scenarioId, 10),
            entrepriseId: entrepriseId,
            startDate: startDate,
            endDate: endDate,
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Error: ${responseData.errors[0].message}`);
                return;
            }

            const masseData = responseData.data?.masseSalarialeChargee;
            if (masseData) {
                setMasseSalarialeChargee(masseData.masseSalarialeChargee);
                setNombrePositions(masseData.nombrePositions);
            }
        } catch (error) {
            setError(`Error fetching masse salariale chargee: ${error.message}`);
        }
    };


    // Fetch budget for the scenario
    useEffect(() => {
        const fetchBudget = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query scenarios($scenarioId: Int!) {
                    scenarios(scenarioId: $scenarioId) {
                        budget
                        name
                        startDate
                        endDate
                        status
                    }
                }
            `;
            const variables = { scenarioId: parseInt(scenarioId, 10) };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }

                const budgetData = responseData.data?.scenarios?.[0]?.budget;
                const nameData = responseData.data?.scenarios?.[0]?.name;
                const startDateData= responseData.data?.scenarios?.[0]?.startDate;
                const endDateData= responseData.data?.scenarios?.[0]?.endDate;

                if (budgetData) {
                    setBudget(budgetData);
                }
                if (nameData) {
                    setName(nameData);
                }
                if (startDateData) {
                    setStartDate(startDateData);
                }
                if (endDateData) {
                    setEndDate(endDateData);
                }

            } catch (error) {
                setError(`Error fetching budget: ${error.message}`);
            }
        };

        fetchBudget();

    }, [scenarioId]);


    useEffect(() => {
        if (startDate && endDate) {
            fetchMasseSalarialeChargee();
        }
    }, [startDate, endDate]);
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'salaryCharged' && parseFloat(value) < 0) {
            setError('Le salaire ne peut pas être négatif.');
            return;
        }
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (id) => {
        setSelectedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const handleRowClick = (position) => {
        setFormData({
            id: position.id,
            job: position.job,
            salaryCharged: position.salaryCharged,
            arrivalMonth: position.arrivalMonth,
            teamId: position.team?.id || '',
            status: position.status,
            justification: position.justification
        });
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteSelected = async () => {
        if (selectedRows.length === 0) {
            setError("Aucune ligne sélectionnée pour la suppression.");
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const mutation = `
            mutation DeleteFutureOpenPositions($ids: [Int!]!) {
                deleteFutureOpenPositions(ids: $ids) {
                    success
                    message
                }
            }
        `;

        const variables = {
            ids: selectedRows.map(id => parseInt(id, 10))
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Erreur: ${responseData.errors[0].message}`);
                return;
            }

            const success = responseData.data?.deleteFutureOpenPositions?.success;
            if (success) {
                setSuccessMessage("Les positions sélectionnées ont été supprimées avec succès !");
                setFuturePositions((prev) =>
                    prev.filter((position) => !selectedRows.includes(position.id))
                );
                setSelectedRows([]);
                fetchMasseSalarialeChargee();
            } else {
                setError(responseData.data?.deleteFutureOpenPositions?.message || "Erreur inconnue.");
            }
        } catch (error) {
            setError(`Erreur lors de la suppression: ${error.message}`);
        }
    };

    const handleBatchStatusChange = async (newStatus) => {
        if (selectedRows.length === 0 || !newStatus) {
            setError("Please select at least one row and a valid status.");
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const mutation = `
            mutation UpdateFutureOpenPosition(
                $id: Int!,
                $job: String!,
                $status: StatusEnum!,
                $salaryCharged: Decimal!,
                $arrivalMonth: Date,
                $teamId: Int!
            ) 
                {
                updateFutureOpenPosition(
                    id: $id,
                    job: $job,
                    status: $status,
                    salaryCharged: $salaryCharged,
                    arrivalMonth: $arrivalMonth,
                    teamId: $teamId
                ) {
                    futureOpenPosition {
                        id
                        job
                        status
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                    }
                }
            }
        `;

        try {
            const updatedPositions = [];

            // Boucle sur chaque ID sélectionné et exécute la mutation
            for (const rowId of selectedRows) {
                const position = futurePositions.find((pos) => pos.id === rowId);

                if (!position) {
                    setError(`Position with ID ${rowId} not found.`);
                    continue;
                }

                const variables = {
                    id: parseInt(rowId, 10),
                    job: position.job,
                    status: newStatus,
                    salaryCharged: parseFloat(position.salaryCharged) || 0,
                    arrivalMonth: position.arrivalMonth || null,
                    teamId: position.team?.id ? parseInt(position.team.id, 10) : null,

                };

                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query: mutation, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Erreur pour ID ${rowId}: ${responseData.errors[0].message}`);
                    continue;
                }

                const updatedPosition = responseData.data.updateFutureOpenPosition.futureOpenPosition;
                updatedPositions.push(updatedPosition);
            }

            // Met à jour l'état avec les nouvelles données
            if (updatedPositions.length > 0) {
                setSuccessMessage("Statut mis à jour avec succès pour les positions sélectionnées !");
                setFuturePositions((prev) =>
                    prev.map((position) =>
                        updatedPositions.find((updated) => updated.id === position.id) || position
                    )
                );
                fetchMasseSalarialeChargee();
                setSelectedRows([]); // Désélectionne les lignes après la mise à jour
            } else {
                setError("Aucune position n'a été mise à jour.");
            }
        } catch (error) {
            setError(`Erreur lors de la mise à jour des statuts : ${error.message}`);
        }
    };

    const scenarioName = `${name} : ${startDate} - ${endDate}`;

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        const mutation = isEditMode
            ? `
            mutation UpdateFutureOpenPosition($id: Int!, $job: String!,$justification: String!,  $status: StatusEnum!, $salaryCharged: Decimal!, $arrivalMonth: Date!, $teamId: Int!) {
                updateFutureOpenPosition(id: $id, job: $job, justification: $justification, status: $status, salaryCharged: $salaryCharged, arrivalMonth: $arrivalMonth, teamId: $teamId) {
                    futureOpenPosition {
                        id
                        job
                        status
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                        justification
                    }
                }
            }`
            : `
            mutation CreateFutureOpenPosition($scenarioId: Int!, $job: String!, $entrepriseId: Int! ,$justification: String!, $salaryCharged: Decimal!, $arrivalMonth: Date!, $teamId: Int!, $requestedById: Int!) {
                createFutureOpenPosition(scenarioId: $scenarioId, job: $job, justification: $justification, entrepriseId: $entrepriseId, salaryCharged: $salaryCharged, arrivalMonth: $arrivalMonth, teamId: $teamId, requestedById:$requestedById) {
                    futureOpenPosition {
                        id
                        job
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                        requestedBy{
                            id
                
                        }
                        justification
                    }
                }
            }`;

        const variables = {
            ...(isEditMode ? { id: parseInt(formData.id, 10) } : { scenarioId: parseInt(scenarioId, 10) }),
            job: formData.job,
            entrepriseId: entrepriseId,
            salaryCharged: parseFloat(formData.salaryCharged),
            arrivalMonth: formData.arrivalMonth,
            teamId: parseInt(formData.teamId, 10),
            status: formData.status,
            requestedById: userId,
            justification: formData.justification || "Nan",
            
            
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Error: ${responseData.errors[0].message}`);
                return;
            }

            setSuccessMessage(isEditMode ? 'Position updated successfully!' : 'Position created successfully!');
            setFuturePositions((prev) => {
                const updatedPosition = responseData.data[
                    isEditMode ? 'updateFutureOpenPosition' : 'createFutureOpenPosition'
                ].futureOpenPosition;
                return isEditMode
                    ? prev.map((position) => (position.id === updatedPosition.id ? updatedPosition : position))
                    : [...prev, updatedPosition];
            });
            setIsEditMode(false);
            setFormData({ id: '', job: '', salaryCharged: '', arrivalMonth: '', teamId: '', status: '' });
            handleCloseModal();
            fetchMasseSalarialeChargee();
        } catch (error) {
            setError(`Error saving position: ${error.message}`);
        }
    };

    return (
        <div className="container-home">
            <Sidebar isCollapsed={true} />
            <div className="container-right">
                <Header />

                <ChatBotComponent
                    scenarioId={scenarioId}
                    onUpdatePositions={updateFuturePositions}
                />
                <div className="line-container-10">
                   
                    <KPISection
                        scenario_name= { scenarioName }
                        masseSalarialeChargee={masseSalarialeChargee}
                        nombrePositions={nombrePositions}
                        budget={budget}
                        scenarioId={scenarioId}
                    />
                   
                    {error && <p className="error-message">{error}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    <div className="actions-container">
                        {selectedRows.length === 0 ? (<></>

                        ) : (

                            <div className="toolbar">
                                        <span>{selectedRows.length} row(s) selected</span>
                                        <select
                                            className="status-select"
                                            onChange={(e) => handleBatchStatusChange(e.target.value)}
                                            defaultValue=""
                                        >
                                            <option value="" disabled>
                                                Change status
                                            </option>
                                            <option value="PLANNED">Planned</option>
                                            <option value="APPROVED">Approved</option>
                                            <option value="CANCELLED">Cancelled</option>
                                        </select>
                                        <button onClick={handleDeleteSelected}>Delete</button>
                            </div>
                        )}
                    </div>
                    <FuturePositionsTable
                        futurePositions={futurePositions}
                        equipes={equipes} // Passe les équipes pour le filtre par équipe
                        selectedRows={selectedRows}
                        onRowClick={handleRowClick}
                        onCheckboxChange={handleCheckboxChange}
                        onAddPosition={() => {
                            setFormData({
                                id: '',
                                job: '',
                                salaryCharged: '',
                                arrivalMonth: '',
                                teamId: '',
                                status: '',
                                justification: '',
                            });
                            setIsEditMode(false);
                            handleOpenModal();
                        }}
                    />

                    {isModalOpen && (
                        <FuturePositionForm
                            isEditMode={isEditMode}
                            formData={formData}
                            equipes={equipes}
                            onInputChange={handleInputChange}
                            onSubmit={handleFormSubmit}
                            onClose={handleCloseModal}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HCScenarioFin;
