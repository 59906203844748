import React, { useState } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import MonthCFOSalary from '../../components/kpi/salary/MonthCFOSalary';
import SalaryByCostType from '../../components/kpi/salary/SalaryByCostType';
import SalaryCFO from './SalaryCFO';
import InsightPayrollTeam from '../../components/insights/payroll/InsightPayrollTeam';

const PayrollTracking = () => {
    const [activeTab, setActiveTab] = useState('Overview'); // État pour gérer l'onglet actif

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Met à jour l'onglet actif
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Overview':
                return (
                    <div className='line-container-10'>
                        <div className='cards-container'>
                            <MonthCFOSalary />
                            <SalaryByCostType />
                        </div>

                        <div className='line-container-10'>
                            <h5>Analytics</h5>
                            <div className='cards-container'>
                                <InsightPayrollTeam />
                            </div>
                        </div>
                    </div>
                );
            case 'Zoom in':
                return <SalaryCFO />;
            default:
                return null;
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            {/* La sidebar se collapse si on est dans Zoom in */}
            <Sidebar isCollapsed={activeTab === 'Zoom in'} /> 

            <div className="container-right">
                {/* Le header n'est affiché que si l'onglet actif n'est pas Zoom in */}
                {activeTab !== 'Zoom in' && <Header />} 

                <div className="tab-menu">
                    <button
                        className={`tab-menu-btn ${activeTab === 'Overview' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Overview')}
                    >
                        Overview
                    </button>
                    <button
                        className={`tab-menu-btn ${activeTab === 'Zoom in' ? 'tab-menu-btn-active' : ''}`}
                        onClick={() => handleTabClick('Zoom in')}
                    >
                        Zoom in
                    </button>
                </div>

                <div className="card-insight-separator"></div>
                <div className='tab-content'>
                    {renderTabContent()}
                </div>
            </div>
        </div>
    );
};

export default PayrollTracking;
