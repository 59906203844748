import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import "../../styles/Chart.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const SalaryChart = ({ entrepriseId, startDate = '2025-01-01', endDate = '2025-12-31' }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const barColorVariable = '#577590';
    const barColorFixed = '#f3722c';

    useEffect(() => {
        const fetchMonthlyKpis = async () => {
            setLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData ? userData.entreprise_id : null;

            if (!startDate || !endDate) {
                setError("Les variables 'startDate' et 'endDate' sont obligatoires.");
                setLoading(false);
                return;
            }

            const query = `
                query GetKpiSalaryByMonth($entrepriseId: Int!, $startDate: String!, $endDate: String!) {
                    kpiSalaryFromHistoriqueMonth(
                        entrepriseId: $entrepriseId,
                        startDate: $startDate,
                        endDate: $endDate
                    ) {
                        month
                        variableCost
                        fixedCost
                        totalCost
                    }
                }
            `;

            const variables = {
                entrepriseId,
                startDate,
                endDate
            };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Erreur: ${responseData.errors[0].message}`);
                    return;
                }

                const kpiData = responseData.data.kpiSalaryFromHistoriqueMonth || [];
                processChartData(kpiData);
            } catch (err) {
                setError(`Erreur lors de la récupération des données : ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchMonthlyKpis();
    }, [entrepriseId, startDate, endDate]);

    const processChartData = (data) => {
        const labels = data.map((item) => item.month);

        const variableCosts = data.map((item) => item.variableCost);
        const fixedCosts = data.map((item) => item.fixedCost);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Variable Cost (€)',
                    data: variableCosts,
                    backgroundColor: barColorVariable,
                },
                {
                    label: 'Fixed Cost (€)',
                    data: fixedCosts,
                    backgroundColor: barColorFixed,
                }
            ],
        });
    };

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                title: { display: true, text: 'Month (MM-YYYY)' }
            },
            y: {
                title: { display: true, text: 'Cost (€)' },
                position: 'left',
                ticks: {
                    beginAtZero: true,
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            }
        }
    };

    if (loading) return <div>Chargement des données...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="card-full">
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default SalaryChart;
