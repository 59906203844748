import React from "react";

const KPISectionSalary = ({ 
    scenario_name, 
    headcount, 
    variableCost, 
    fixedCost, 
    totalStaffCost 
}) => {
    return (
        <div className="line-container-10">
          

            <div className="display-line-flex">
                {/* Vue KPI */}
                <div className="card-kpi-staff">
                    <h5>Headcount</h5>
                    <p>{headcount}</p>
                </div>

                <div className="card-kpi-staff">
                    <h5>Variable Cost</h5>
                    <p>
                        {parseFloat(variableCost).toLocaleString("fr-FR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                        {" €"}
                    </p>
                </div>

                <div className="card-kpi-staff">
                    <h5>Fixed Cost</h5>
                    <p>
                        {parseFloat(fixedCost).toLocaleString("fr-FR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                        {" €"}
                    </p>
                </div>

                <div className="card-kpi-staff">
                    <h5>Total Staff Cost</h5>
                    <p>
                        {parseFloat(totalStaffCost).toLocaleString("fr-FR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                        {" €"}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default KPISectionSalary;
