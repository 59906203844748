import React, { useState, useEffect } from "react";
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/List.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";
import TeamSection from "./TeamSection";
import CreateTeam from "../../components/recrutement/CreateTeamForm";
import CreateTeamForm from "../../components/recrutement/CreateTeamForm";

const ParamTeam = () => {
    const [groupedData, setGroupedData] = useState([]);
    const [availableManagers, setAvailableManagers] = useState([]);
    const [availableRecruiters, setAvailableRecruiters] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false); // État pour la modale

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");

    const fetchGroupedData = async () => {
        const groupUrl = `${apiUrl}/graphql/`;
        const query = `
            query ($entrepriseId: Int!) {
                equipes(entrepriseId: $entrepriseId) {
                    id
                    nomEquipe
                    description
                    mainManager {
                        id
                        firstName
                        lastName
                    }
                    managers {
                        id
                        firstName
                        lastName
                    }
                    recruiters {
                        id
                        firstName
                        lastName
                    }
                }
            }
        `;

        const userData = JSON.parse(localStorage.getItem("userData"));
        const entrepriseId = userData?.entreprise_id;

        if (!entrepriseId) {
            setError("Entreprise ID introuvable.");
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(groupUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({
                    query,
                    variables: { entrepriseId: parseInt(entrepriseId, 10) },
                }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des données.");
            }

            const { data, errors } = await response.json();

            if (errors) {
                throw new Error("Échec de la requête GraphQL.");
            }

            const formattedData = data.equipes.map((equipe) => ({
                ...equipe,
                id: parseInt(equipe.id, 10),
                mainManager: equipe.mainManager || null,
                managers: equipe.managers || [],
                recruiters: equipe.recruiters || [],
            }));

            const allManagers = formattedData.flatMap((e) => e.managers);
            const allRecruiters = formattedData.flatMap((e) => e.recruiters);

            setGroupedData(formattedData);
            setAvailableManagers(allManagers);
            setAvailableRecruiters(allRecruiters);
        } catch (error) {
            setError("Impossible de charger les données.");
        } finally {
            setLoading(false);
        }
    };

    const updateTeamMutation = async (updatedTeam) => {
        setSaving(true);

        const mutationUrl = `${apiUrl}/graphql/`;
        const mutation = `
            mutation UpdateTeam($id: Int!, $mainManager: Int, $managers: [Int!], $recruiters: [Int!]) {
                updateEquipe(
                    id: $id
                    mainManager: $mainManager
                    managers: $managers
                    recruiters: $recruiters
                ) {
                    equipe {
                        id
                        nomEquipe
                        mainManager {
                            id
                            firstName
                            lastName
                        }
                        managers {
                            id
                            firstName
                            lastName
                        }
                        recruiters {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        `;

        const variables = {
            id: parseInt(updatedTeam.id, 10),
            mainManager: updatedTeam.mainManager ? parseInt(updatedTeam.mainManager.id, 10) : 0,
            managers: updatedTeam.managers.map((m) => parseInt(m.id, 10)),
            recruiters: updatedTeam.recruiters.map((r) => parseInt(r.id, 10)),
        };

        try {
            const response = await fetch(mutationUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour de l'équipe.");
            }

            const { data, errors } = await response.json();

            if (errors) {
                throw new Error("Mutation GraphQL échouée.");
            }

            setSelectedTeam(data.updateEquipe.equipe);
        } catch (error) {
            setError("Impossible de mettre à jour l'équipe.");
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchGroupedData();
    }, [apiUrl, token]);

    const handleTeamChange = (event) => {
        const teamId = parseInt(event.target.value, 10);
        const team = groupedData.find((t) => t.id === teamId);

        if (team) {
            setSelectedTeam({
                ...team,
                mainManager: team.mainManager || null,
                managers: team.managers || [],
                recruiters: team.recruiters || [],
            });
        } else {
            setSelectedTeam(null);
        }
    };
    const handleAddMember = (member, type) => {
        if (!selectedTeam) return;
    
        if (type === "mainManager") {
            const updatedTeam = {
                ...selectedTeam,
                mainManager: member, // Remplacer directement par le nouveau manager
            };
    
            setSelectedTeam(updatedTeam);
            updateTeamMutation(updatedTeam);
            return;
        }
    
        const currentMembers = Array.isArray(selectedTeam[type]) ? selectedTeam[type] : [];
        if (currentMembers.some((m) => m.id === member.id)) return;
    
        const updatedTeam = {
            ...selectedTeam,
            [type]: [...currentMembers, member],
        };
    
        setSelectedTeam(updatedTeam);
        updateTeamMutation(updatedTeam);
        fetchGroupedData();
    };
    

    const handleRemoveMember = (member, type) => {
        if (!selectedTeam) return;
    
        if (type === "mainManager") {
            const updatedTeam = {
                ...selectedTeam,
                mainManager: null, // Supprimer le mainManager en le mettant à null
            };
    
            setSelectedTeam(updatedTeam);
            updateTeamMutation(updatedTeam);
            return;
        }
    
        const currentMembers = Array.isArray(selectedTeam[type]) ? selectedTeam[type] : [];
        const updatedTeam = {
            ...selectedTeam,
            [type]: currentMembers.filter((m) => m.id !== member.id),
        };
    
        setSelectedTeam(updatedTeam);
        updateTeamMutation(updatedTeam);
        fetchGroupedData();
    };
    

    const handleOpenCreateTeamModal = () => setShowCreateTeamModal(true);
    const handleCloseCreateTeamModal = () => setShowCreateTeamModal(false);

    return (
        <div className="card-full">
            <div className="line-container-10">
                <div className="display-line-center dropdown">
                    <h5>Select the team</h5>

                    {loading ? (
                        <div>
                            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                        </div>
                    ) : (
                        <select className="dropdown" onChange={handleTeamChange} defaultValue="">
                            <option value="" disabled>
                                Select a team
                            </option>
                            {groupedData.map((team) => (
                                <option key={team.id} value={team.id}>
                                    {team.nomEquipe}
                                </option>
                            ))}
                        </select>
                        
                    )}
                                    <button type='submit' onClick={handleOpenCreateTeamModal}>
                    <FontAwesomeIcon icon={faPlus} /> Create New Team
                </button>
                </div>

                {selectedTeam && (
                    <>
                        <TeamSection
                            apiUrl={apiUrl}
                            token={token}
                            title="Main Manager"
                            type="mainManager"
                            members={selectedTeam.mainManager ? [selectedTeam.mainManager] : []} // Affiche le mainManager s'il existe
                            availableMembers={availableManagers}
                            handleAddMember={handleAddMember}
                            handleRemoveMember={handleRemoveMember}
                        />

                        <div className="perf-form-separator"></div>
                        <TeamSection
                            apiUrl={apiUrl}
                            token={token}
                            title="Managers"
                            type="managers"
                            members={selectedTeam.managers}
                            availableMembers={availableManagers}
                            handleAddMember={handleAddMember}
                            handleRemoveMember={handleRemoveMember}
                        />
                        <div className="perf-form-separator"></div>
                        <TeamSection
                            apiUrl={apiUrl}
                            token={token}
                            title="Recruiters"
                            type="recruiters"
                            members={selectedTeam.recruiters}
                            availableMembers={availableRecruiters}
                            handleAddMember={handleAddMember}
                            handleRemoveMember={handleRemoveMember}
                        />
                    </>
                )}

                {saving && (
                    <div>
                        <FontAwesomeIcon icon={faSpinner} spin /> Saving...
                    </div>
                )}
            </div>

            {showCreateTeamModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="modal-close" onClick={handleCloseCreateTeamModal}>
                            X
                        </button>

                        <CreateTeamForm onClose={handleCloseCreateTeamModal} onTeamCreated={fetchGroupedData} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParamTeam;
