import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend);

const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphStaffEffectif = () => {
    const currentYear = new Date().getFullYear();
    const endOfYear = new Date(`${currentYear}-12-31`);
    const lastYear = new Date(`${currentYear}-01-01`);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(endOfYear));
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [kpiData, setKpiData] = useState({});

    const teamColors = [
        '#f94144', '#f3722c',  '#90be6d', '#577590', '#9381ff', '#001d3d', '#f72585'
    ];

    const processChartData = (data) => {
        const labels = data.map(item => item.periode);
        const teamNames = [...new Set(data.flatMap(item => item.teams.map(team => team.team__nom_equipe)))];
    
        const datasets = teamNames.map((teamName, index) => ({
            label: teamName,
            data: labels.map(label => data.find(item => item.periode === label)?.teams.find(team => team.team__nom_equipe === teamName)?.team_count || 0),
            type: 'bar',
            backgroundColor: teamColors[index % teamColors.length],
            borderColor: teamColors[index % teamColors.length],
            borderWidth: 1,
        }));
    
        setChartData({
            labels,
            datasets
        });
    };
    

    const fetchFilterOptions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/historique_et_estimations/`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) throw new Error('Error fetching filter options');
            const data = await response.json();
            setFilterOptions(data.filters);
        } catch (error) {
            console.error('Error fetching filter options', error);
        }
    };

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const filterParams = filters.map(filter => `${filter.column}=${filter.value}`).join('&');
        const url = `${apiUrl}/openposition/historique_et_estimations/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}&${filterParams}`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) throw new Error('Error fetching data');
            const { data } = await response.json();
            processChartData(data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchFilterOptions();
        fetchData();
    }, [startDate, endDate, groupOption, filters]);

    const handleGroupChange = (event) => setGroupOption(event.target.value);
    const handleStartDateChange = (event) => setStartDate(event.target.value);
    const handleEndDateChange = (event) => setEndDate(event.target.value);
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };
    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            let displayValue = currentFilter.column === 'team'
                ? filterOptions.teams.find(team => team.id === parseInt(currentFilter.value, 10))?.nom_equipe
                : currentFilter.value;
            setFilters([...filters, { ...currentFilter, displayValue }]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };
    const handleRemoveFilter = (index) => setFilters(filters.filter((_, i) => i !== index));

    const chartOptions = {
        responsive: true,
        scales: {
            x: { title: { display: true, text: 'Period' }, stacked: true },
            y: { title: { display: true, text: 'Employees' }, stacked: true },
        },
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>

            <Sidebar isCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">
                <FiltersComponent
                    showDateRange={true}
                    showGroupOption={true}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    groupOption={groupOption}
                    handleGroupChange={handleGroupChange}
                    filterOptions={filterOptions}
                    currentFilter={currentFilter}
                    handleFilterChange={handleFilterChange}
                    handleAddFilter={handleAddFilter}
                    filters={filters}
                    handleRemoveFilter={handleRemoveFilter}
                />

                <div className='container-data'>
                    <ChartWithKPIsComponent
                        title="Employee Arrival by Period"
                        kpiDataArray={[]}
                        chartType="bar"
                        chartData={chartData}
                        chartOptions={chartOptions}
                    />
                </div>
                </div>
            </div>
        </div>
    );
};

export default GraphStaffEffectif;
