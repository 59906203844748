import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../../cards/HomeCards';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const MonthCFOSalary = ({ team }) => {
    const [chartData, setChartData] = useState(null);
    const [forecast12months, setForecast12months] = useState('Chargement...');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const startDate = '2025-01-01'; // Définir une valeur par défaut
    const endDate = '2025-12-31'; // Définir une valeur par défaut

    const handleOnClick = () => {
        
        navigate('/payroll/salary?groupBy=costType');
    };

    useEffect(() => {
        const fetchMonthlyKpis = async () => {
            setLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData ? userData.entreprise_id : null;

            if (!startDate || !endDate) {
                setError("Les variables 'startDate' et 'endDate' sont obligatoires.");
                setLoading(false);
                return;
            }

            const query = `
            query GetKpiSalaryByMonth($entrepriseId: Int!, $startDate: String!, $endDate: String!) {
                kpiSalaryFromHistoriqueMonth(
                    entrepriseId: $entrepriseId,
                    startDate: $startDate,
                    endDate: $endDate,
                    groupBy: "costType"
                ) {
                    group
                    data {
                        month
                        variableCost
                        fixedCost
                        totalCost
                    }
                }
            }
        `;
        
        const variables = {
            entrepriseId,
            startDate,
            endDate,
        };
        

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Erreur: ${responseData.errors[0].message}`);
                    return;
                }

                const kpiData = responseData.data.kpiSalaryFromHistoriqueMonth || [];
                processChartData(kpiData);
            } catch (err) {
                setError(`Erreur lors de la récupération des données : ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchMonthlyKpis();
    }, [team, startDate, endDate]); // Ajout de `team` comme dépendance

    const formatForecast = (value) => {
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}m €`;
        } else if (value >= 100000) {
            return `${Math.round(value / 1000)}k €`;
        } else {
            return `${Math.round(value)} €`;
        }
    };


    const processChartData = (data) => {
        // Extraire les données du groupe "All"
        const groupData = data.find((group) => group.group === "All")?.data || [];
    
        // Extraire les labels (mois) et les données pour chaque catégorie de coût
        const labels = groupData.map((item) => item.month);
        const variableCosts = groupData.map((item) => item.variableCost);
        const fixedCosts = groupData.map((item) => item.fixedCost);
        const totalCosts = groupData.map((item) => item.totalCost);
    
        // Définir la prévision pour les 12 mois comme le dernier coût total
        const lastTotalCost = totalCosts.length > 0 ? totalCosts[totalCosts.length - 1] : 0;
        setForecast12months(formatForecast(lastTotalCost));
    
        // Mettre à jour les données du graphique
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Variable Cost (€)',
                    data: variableCosts,
                    backgroundColor: 'rgba(87, 117, 144, 0.8)', // Couleur pour les coûts variables
                    borderColor: 'rgba(87, 117, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Fixed Cost (€)',
                    data: fixedCosts,
                    backgroundColor: 'rgba(243, 114, 44, 0.8)', // Couleur pour les coûts fixes
                    borderColor: 'rgba(243, 114, 44, 1)',
                    borderWidth: 1,
                },
           
            ],
        });
    };
    
    
    


    const chartOptions = {
        scales: {
            y: {
                

                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
            x: {
                stacked: true,
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0,
            },
            line: {
                borderWidth: 3,
            }
        },
        maintainAspectRatio: false,
    };


    if (error) return <div>{error}</div>;

    return (
        <HomeCards
            text= "Staff cost evolution over 12 months, grouped by cost categories."
            kpi={forecast12months}
            chart={chartData && <Bar data={chartData} options={chartOptions} height={65} width={1000} />}
            onClick={handleOnClick}
        />
    );
};

export default MonthCFOSalary;
