import React, { useState, useEffect } from 'react';
import KPISectionSalary from '../../components/massesalary/KPISectionSalary';
import SalaryTableau from '../../components/massesalary/SalaryTableau';
import SalaryChart from '../../components/massesalary/SalaryChart';
import '../../styles/Payroll.css';


function toInputDateString(dateObj) {
  return dateObj.toISOString().split('T')[0];
}

const SalaryCFO = () => {
    const firstDayOfYear = new Date(2025, 0, 1);
    const currentDay = new Date(2025, 11, 32); 

    const [filters, setFilters] = useState({
        department: '',
        dateRange: {
            start: toInputDateString(firstDayOfYear),
            end: toInputDateString(currentDay),
        },
    });

    const [tempFilters, setTempFilters] = useState(filters); // Filtres temporaires
    const [kpiData, setKpiData] = useState(null);

    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('table');
    const [employeeData, setEmployeeData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const entrepriseId = userData ? userData.entreprise_id : null;

    const fetchData = async (appliedFilters) => {

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: `
                        query GetData(
                            $entrepriseId: Int!, 
                            $startDate: String!, 
                            $endDate: String!, 
                            $department: String
                        ) {
                            kpiSalary(
                                entrepriseId: $entrepriseId, 
                                startDate: $startDate, 
                                endDate: $endDate, 
                                department: $department
                            ) {
                                headcount
                                variableCost
                                fixedCost
                                totalStaffCost
                            }
                            employeeKpiSalary(
                                entrepriseId: $entrepriseId, 
                                startDate: $startDate, 
                                endDate: $endDate, 
                                department: $department
                            ) {
                                id
                                name
                                position
                                department
                                startDate
                                endDate
                                grossSalary
                                bonus
                                benefits
                                employerContributions
                                overallCost
                            }
                        }
                    `,
                    variables: { 
                        entrepriseId,
                        startDate: appliedFilters.dateRange.start,
                        endDate: appliedFilters.dateRange.end,
                        department: appliedFilters.department || null,
                    },
                }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Erreur: ${responseData.errors[0].message}`);
            } else {
                setKpiData(responseData.data.kpiSalary);
                setEmployeeData(responseData.data.employeeKpiSalary);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Une erreur est survenue lors de la récupération des données.");
        } finally {

        }
    };

    useEffect(() => {
        fetchData(filters);
    }, [filters]);

    const applyFilters = () => {
        setFilters(tempFilters);
    };

    const toggleView = () => setViewMode(viewMode === 'table' ? 'chart' : 'table');

    const handleTempFilterChange = (field, value) => {
        setTempFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }));
    };

    const handleDateRangeChange = (key, newValue) => {
        setTempFilters((prevFilters) => ({
            ...prevFilters,
            dateRange: {
                ...prevFilters.dateRange,
                [key]: newValue,
            },
        }));
    };

    const downloadEmployeeCSV = () => {
        const csvHeader = "Name,Position,Department,Start Date,Gross Salary,Bonus,Benefits,Employer Contributions,Overall Cost\n";
        const csvRows = employeeData.map((emp) =>
            `${emp.name},${emp.position},${emp.department},${emp.startDate},${emp.grossSalary},${emp.bonus},${emp.benefits},${emp.employerContributions},${emp.overallCost}`
        );
        const blob = new Blob([csvHeader + csvRows.join("\n")], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "employee_data.csv";
        link.click();
    };


    if (error) return <p>{error}</p>;

    return (
        <div className="line-container-10">
             <div className="filters-container-center">
                    <input
                        type="date"
                        value={tempFilters.dateRange.start}
                        onChange={(e) => handleDateRangeChange('start', e.target.value)}
                        placeholder="Start Date"
                        className='chart-dates'
                    />
                    <input
                        type="date"
                        value={tempFilters.dateRange.end}
                        onChange={(e) => handleDateRangeChange('end', e.target.value)}
                        placeholder="End Date"
                        className='chart-dates'
                    />
                    <select
                        value={tempFilters.department}
                        onChange={(e) => handleTempFilterChange('department', e.target.value)}
                        className='chart-dates'
                    >
                        <option value="">All Departments</option>
                        {Array.from(new Set(employeeData.map((emp) => emp.department)))
                            .map((dept) => (
                                <option key={dept} value={dept}>
                                    {dept}
                                </option>
                        ))}
                    </select>

                <button onClick={applyFilters} type="submit" >
                    Valider
                </button>
                </div>
            <div className="cards-container">
                {kpiData && (
                    <KPISectionSalary
                        scenario_name="Current Scenario"
                        headcount={kpiData.headcount}
                        variableCost={kpiData.variableCost}
                        fixedCost={kpiData.fixedCost}
                        totalStaffCost={kpiData.totalStaffCost}
                    />
                )}
            </div>

            <div className="display-line">
               
                
                <button onClick={downloadEmployeeCSV} className="button-cfo">
                    Exporter les données en CSV
                </button>
                <button onClick={toggleView} className="button-cfo">
                    {viewMode === 'table' ? 'Show Chart' : 'Show Table'}
                </button>
            </div>

            {viewMode === 'table' ? (
                <SalaryTableau employees={employeeData} />
            ) : (
                <SalaryChart  startDate={filters.dateRange.start} endDate={filters.dateRange.end}/>
            )}
        </div>
    );
};

export default SalaryCFO;
