import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import HomeCards from '../../cards/HomeCards';
import { useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const SalaryByCostType = () => {
    const [chartData, setChartData] = useState(null);
    const [forecast12months, setForecast12months] = useState('Chargement...');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const startDate = '2025-01-01';
    const endDate = '2025-12-31';

    const handleOnClick = () => {
        navigate('/payroll/salary?groupBy=costType');
    };

    useEffect(() => {
        const fetchMonthlyKpis = async () => {
            setLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData ? userData.entreprise_id : null;

            if (!startDate || !endDate) {
                setError("Les variables 'startDate' et 'endDate' sont obligatoires.");
                setLoading(false);
                return;
            }

            const query = `
                query GetKpiSalaryByMonth($entrepriseId: Int!, $startDate: String!, $endDate: String!) {
                    kpiSalaryFromHistoriqueMonth(
                        entrepriseId: $entrepriseId,
                        startDate: $startDate,
                        endDate: $endDate,
                        groupBy: "costType"
                    ) {
                        group
                        data {
                            month
                            variableCost
                            fixedCost
                            totalCost
                        }
                    }
                }
            `;

            const variables = {
                entrepriseId,
                startDate,
                endDate,
            };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Erreur: ${responseData.errors[0].message}`);
                    return;
                }

                const kpiData = responseData.data.kpiSalaryFromHistoriqueMonth || [];
                processChartData(kpiData);
            } catch (err) {
                setError(`Erreur lors de la récupération des données : ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchMonthlyKpis();
    }, [startDate, endDate]);

    const processChartData = (data) => {
        // Extraire les données du groupe "All"
        const groupData = data.find((group) => group.group === "All")?.data || [];
    
        // Trouver les données du dernier mois
        const lastMonthData = groupData[groupData.length - 1]; // Dernier élément du tableau
        const lastMonthVariableCost = lastMonthData?.variableCost || 0;
        const lastMonthFixedCost = lastMonthData?.fixedCost || 0;
        const lastMonthTotalCost = lastMonthData?.totalCost || 0;
    
        // Mettre à jour les données pour le graphique
        setChartData({
            labels: ['Variable Cost', 'Fixed Cost', 'Total Cost'],
            datasets: [
                {
                    data: [lastMonthVariableCost, lastMonthFixedCost],
                    backgroundColor: ['rgba(87, 117, 144, 0.8)', 'rgba(243, 114, 44, 0.8)'],
                    borderColor: ['rgba(87, 117, 144, 1)', 'rgba(243, 114, 44, 1)'],
                    borderWidth: 1,
                },
            ],
        });
    
        // Mettre à jour le KPI pour le dernier mois
        setForecast12months(`${formatForecast(lastMonthTotalCost)}`);
    };
    

    const formatForecast = (value) => {
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}m €`;
        if (value >= 100000) return `${Math.round(value / 1000)}k €`;
        return `${Math.round(value)} €`;
    };
    const chartOptions = {
        scales: {
            y: {
                

                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
            x: {
                stacked: true,
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0,
            },
            line: {
                borderWidth: 3,
            }
        },
        maintainAspectRatio: false,
    };

    if (error) return <div>{error}</div>;


    return (
        <HomeCards
            text="Staff cost repartition, grouped by type of costs."
            kpi={forecast12months}
            chart={
                chartData && (
                    <div style={{ maxHeight: '70px', height: '125px', overflow: 'hidden' }}>
                        <Pie data={chartData} options={chartOptions} />
                    </div>
                )
            }
            onClick={handleOnClick}
        />
    );
};

export default SalaryByCostType;
