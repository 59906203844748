import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Container.css";
import "../../styles/Card.css";
import "../../styles/Perf.css";
import EditableMarkdown from '../../components/cards/EditableMarkdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faQuestionCircle, faGem, faBriefcase, faArrowUp, faKey, faStar, faTrophy } from '@fortawesome/free-solid-svg-icons';
import amplitude from 'amplitude-js';

const EmployeView = () => {
    const { employe_id } = useParams();
    const [devPlanContent, setDevPlanContent] = useState(""); // Initialiser avec une chaîne vide
    const [employe, setEmploye] = useState(null);
    const [error, setError] = useState(null);
    const [isFinalized, setIsFinalized] = useState(false);
    const [activeTab, setActiveTab] = useState('Reviews en cours'); // Onglet actif par défaut
    const [currentDevPlan, setCurrentDevPlan] = useState({}); // Stocker les détails du plan de développement actuel
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    // Fonction pour mettre à jour le plan de développement
    const updateDevPlan = async (devPlanId, newPlanDetails) => {
        const updateUrl = `${apiUrl}/api/employe-details/${employe_id}/`;

        try {
            const response = await fetch(updateUrl, {
                credentials: 'include',
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dev_plan_id: devPlanId, plan_details: newPlanDetails })
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to update development plan:', errorData);
                throw new Error('Failed to update development plan');
            }

            const updatedData = await response.json();
            console.log('Development plan updated successfully:', updatedData);
            fetchEmployeDetails(); // Rafraîchir les détails de l'employé après la mise à jour
        } catch (error) {
            console.error('Error updating development plan:', error);
            setError('Failed to update development plan. Please try again later.');
        }
    };

    // Fonction pour gérer la sauvegarde du plan de développement
    const handleDevPlanSave = (devPlanId, newPlanDetails) => {
        updateDevPlan(devPlanId, newPlanDetails);
        amplitude.getInstance().logEvent('PerfDevPlanSave', {
            username: ampData.username || 'unknown',
            devPlanID: devPlanId,
            details: newPlanDetails,
        });
    };

    // Fonction pour récupérer les détails de l'employé
    const fetchEmployeDetails = useCallback(async () => {
        if (!employe_id) return;
        const detailsUrl = `${apiUrl}/api/employe-details/${employe_id}/`;
        try {
            const response = await fetch(detailsUrl, {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setEmploye(data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, [employe_id, apiUrl, token]);

    useEffect(() => {
        fetchEmployeDetails();
    }, [fetchEmployeDetails]);

    // Fonction pour obtenir le dernier historique d'emploi
    const getLastEmploymentHistory = (historique_emploi) => {
        if (historique_emploi && historique_emploi.length > 0) {
            return historique_emploi[historique_emploi.length - 1];
        }
        return null;
    };

    const lastEmployment = employe ? getLastEmploymentHistory(employe.historique_emploi) : null;

    // Fonction pour mettre à jour le statut de la revue
    const updateReviewStatus = async (reviewId, newStatus) => {
        const updateUrl = `${apiUrl}/reviews_by_person/${reviewId}/update_status/`;
        try {
            const response = await fetch(updateUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
            if (!response.ok) {
                throw new Error('Failed to update status');
            }
            const updatedReview = await response.json();
            setEmploye(prevEmploye => {
                const updatedReviewsByPersonn = { ...prevEmploye.reviews_by_personn };
                for (let key in updatedReviewsByPersonn) {
                    updatedReviewsByPersonn[key] = updatedReviewsByPersonn[key].filter(review =>
                        review.id !== reviewId
                    );
                }
                if (newStatus === 'C') {
                    updatedReviewsByPersonn.closed.push(updatedReview);
                } else {
                    updatedReviewsByPersonn[newStatus.toLowerCase()].push(updatedReview);
                }
                return { ...prevEmploye, reviews_by_personn: updatedReviewsByPersonn };
            });
        } catch (error) {
            console.error('Error updating status:', error);
            setError('Failed to update status. Please try again later.');
        }
    };

    // Fonction pour gérer le changement de statut
    const handleStatusChange = (reviewId, newStatus) => {
        updateReviewStatus(reviewId, newStatus);
    };

    // Fonction pour finaliser la revue
    const handleFinalizeReview = (reviewId) => {
        amplitude.getInstance().logEvent('PerfReviewFinalize', {
            username: ampData.username || 'unknown',
            reviewID: reviewId,
        });
        handleStatusChange(reviewId, 'C');  // Changer le statut en 'Closed'
        setIsFinalized(true);
    };

    // Options de statut pour les revues
    const statusOptions = [
        { value: 'O', label: 'Open' },
        { value: 'C', label: 'Closed' },
        { value: 'I', label: 'In Progress' },
        { value: 'P', label: 'Pending' },
    ];

    // Fonction pour obtenir la classe CSS en fonction du statut
    const getStatusClass = (status) => {
        switch (status) {
            case 'O':
                return 'status-open status-select';
            case 'C':
                return 'status-closed status-select';
            case 'I':
                return 'status-in-progress status-select';
            case 'P':
                return 'status-pending status-select';
            default:
                return '';
        }
    };

    // Fonction pour obtenir les icônes et les labels en fonction des scores de performance et de potentiel
    const getReviewLabel = (performance, potentiel) => {
        if (performance <= 2 && potentiel <= 2) {
            return { icon: faExclamationTriangle, label: 'Sous performeur', colorIcone: '#FC123D' };
        } else if (performance <= 2 && potentiel === 3) {
            return { icon: faQuestionCircle, label: 'Inconsistant', colorIcone: '#F39166' };
        } else if (performance <= 2 && potentiel >= 4) {
            return { icon: faGem, label: 'Diamant brut', colorIcone: '#70CCCC' };
        } else if (performance === 3 && potentiel <= 2) {
            return { icon: faBriefcase, label: 'Professionnel solide', colorIcone: '#F39166' };
        } else if (performance === 3 && potentiel === 3) {
            return { icon: faKey, label: 'Employé clé', colorIcone: '#70CCCC' };
        } else if (performance === 3 && potentiel >= 4) {
            return { icon: faStar, label: 'Future star', colorIcone: '#7AE775' };
        } else if (performance >= 4 && potentiel <= 2) {
            return { icon: faArrowUp, label: 'Employé de confiance', colorIcone: '#70CCCC' };
        } else if (performance >= 4 && potentiel === 3) {
            return { icon: faStar, label: 'Star actuelle', colorIcone: '#7AE775' };
        } else if (performance >= 4 && potentiel >= 4) {
            return { icon: faTrophy, label: 'Futur Leader', colorIcone: '#0F9508' };
        }
        return { icon: null, label: '' };
    };
    
    // Fonction pour afficher le contenu de l'onglet actif
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Reviews en cours':
                return (
                    <div>
                        <div className="perf-reviews-container">
                            {filteredReviewsInProgress.length > 0 ? (
                                filteredReviewsInProgress.map((review, index) => {
                                    const { icon, label, colorIcone } = getReviewLabel(review.performance, review.potentiel);
                                    const devPlanContent = currentDevPlan[review.dev_plan.id] || review.dev_plan.plan_details; // Contenu actuel ou contenu initial
                                    return (
                                        <div key={index} className={`${isFinalized ? 'slide-down' : ''}`}>
                                            {error && <p>{error}</p>}

                                            {/* Section Notes */}
                                            <div className="line-container-10">
                                                <div className='perf-employe-subtitle'>Notes</div>
                                                <div className="display-line">
                                                    <FontAwesomeIcon icon={icon} style={{ color: colorIcone }} />
                                                    {label}
                                                    <select
                                                        id="status-select"
                                                        value={review.status}
                                                        onChange={(e) => handleStatusChange(review.id, e.target.value)}
                                                        className={`${getStatusClass(review.status)} status-select`}
                                                    >
                                                        {statusOptions.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Separator */}
                                            <div className="perf-form-separator"></div>

                                            {/* Section Development Plan */}
                                            <div className="line-container-10">
                                                <div className='perf-employe-subtitle'>Development plan</div>
                                                <div className='perf-dev'>      
                                                    {review.dev_plan ? (
                                                        <EditableMarkdown 
                                                        className="perf-edit" 
                                                        initialContent={review.dev_plan.plan_details} 
                                                        contentModified={(newContent) => setCurrentDevPlan({
                                                            ...currentDevPlan,
                                                            [review.dev_plan.id]: newContent
                                                        })}
                                                    />
                                                    
                                                    ) : (
                                                        <p>No development plan</p>
                                                    )}
                                                </div>
                                                {/* Display the content of EditableMarkdown */}

                                                <button type="submit"
                                                    onClick={() => handleDevPlanSave(review.dev_plan.id, devPlanContent)}
                                                >
                                                    Save
                                                </button>
                                            </div>

                                            {/* Finalize Button */}
                                            <div className="line-container-10">
                                                <button type="submit" onClick={() => handleFinalizeReview(review.id)}>
                                                    Finalize the review
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>No reviews in progress found</p>
                            )}
                        </div>
                    </div>
                );
            case 'Reviews terminées':
                return (
                    <div>
                        <div className="perf-reviews-container">
                            {filteredReviewsCompleted.length > 0 ? (
                                filteredReviewsCompleted.map((review, index) => {
                                    const { icon, label, colorIcone } = getReviewLabel(review.performance, review.potentiel);
                                    return (
                                        <div key={index} className={`${isFinalized ? 'slide-down' : ''}`}>
                                            {error && <p>{error}</p>}

                                            {/* Review Name */}
                                            <div className="line-container-10">
                                                <h5>Review : {review.review_name} </h5>
                                                <div className="display-line">
                                                    <FontAwesomeIcon icon={icon} style={{ color: colorIcone }} />
                                                    {label}
                                                    <button className='btn-secondary' onClick={() => handleFinalizeReview(review.id)}>
                                                        Download the review
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })
                            ) : (
                                <p>No completed reviews found</p>
                            )}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // Filtrer les revues en cours
    const filteredReviewsInProgress = employe && employe.reviews_by_personn
        ? [
            ...employe.reviews_by_personn.open || [],
            ...employe.reviews_by_personn.in_progress || [],
            ...employe.reviews_by_personn.pending || []
        ]
        : [];
    
    // Filtrer les revues terminées
    const filteredReviewsCompleted = employe && employe.reviews_by_personn
        ? [
            ...employe.reviews_by_personn.closed || []
        ]
        : [];

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar isCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className='container-perf'>
                    <div className='card-full'>
                        <div className='container-perf-left-head'>
                            <h4>{employe ? `${employe.first_name} ${employe.last_name}` : 'Loading...'}</h4>
                            {lastEmployment ? (
                                <div>
                                    <p>{lastEmployment.metier}</p>
                                </div>
                            ) : (
                                <p>No employment history found</p>
                            )}
                        </div>

                        <div className="card-insight-separator"></div>

                        <div className="line-container-10">
                            <div className='perf-employe-subtitle'>Team</div>
                            {lastEmployment ? (
                                <p>{lastEmployment.equipe}</p>
                            ) : (
                                <p>No employment history found</p>
                            )}
                        </div>
                    </div>
                    <div className='card-full'>
                        <div className="tab-menu">
                            <button
                                className={`tab-menu-btn ${activeTab === 'Reviews en cours' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Reviews en cours')}
                            >
                                Reviews en cours
                            </button>
                            <button
                                className={`tab-menu-btn ${activeTab === 'Reviews terminées' ? 'tab-menu-btn-active' : ''}`}
                                onClick={() => setActiveTab('Reviews terminées')}
                            >
                                Reviews terminées
                            </button>
                        </div>
                        <div>
                            {renderTabContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeView;
